
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { GovernoratesObject } from '@/store/modules/assets/GovernoratesModule';

  export default defineComponent({
    name: 'governorate-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('governorateUpdating'), [
          translate('assets'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_ONE_GOVERNORATE,
        route.params.id
      );

      const governorate = ref<GovernoratesObject>(data.data);

      const titleAr = () => {
        return governorate.value.title.find((i) => {
          if (i.languageCode === 'ar') {
            return i.title;
          }
        }).title;
      };
      const titleEn = () => {
        return governorate.value.title.find((i) => {
          if (i.languageCode === 'en') {
            return i.title;
          }
        }).title;
      };
      const titleKu = () => {
        return governorate.value.title.find((i) => {
          if (i.languageCode === 'ku') {
            return i.title;
          }
        }).title;
      };

      const goBack = () => {
        router.back();
      };

      const governorateSchema = Yup.object().shape({
        titleAr: Yup.string()
          .required(() => translate('AR_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ar'),
        titleEn: Yup.string()
          .required(() => translate('EN_TITLE_IS_REQUIRED_FIELD'))
          .label('Title En'),
        titleKu: Yup.string()
          .required(() => translate('KU_TITLE_IS_REQUIRED_FIELD'))
          .label('Title Ku'),
      });
      const onSubmitCreate = async (values) => {
        values.title = [];
        values.description = [];
        values.title.push({ languageCode: 'en', title: values.titleEn });
        values.title.push({ languageCode: 'ar', title: values.titleAr });
        values.title.push({ languageCode: 'ku', title: values.titleKu });

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (values.isActive == true) {
          values.isActive = true;
        } else {
          values.isActive = false;
        }

        const payload = {
          isActive: values.isActive,
          title: values.title,
        };
        await store.dispatch(Actions.UPDATE_GOVERNORATE, {
          data: payload,
          id: governorate.value?.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_GOVERNORATE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'governorates-listing' });
          });
        }
      };

      return {
        titleAr,
        titleEn,
        titleKu,
        onSubmitCreate,
        selectedItem,
        submitButton,
        governorate,
        translate,
        governorateSchema,
        goBack,
        can,
      };
    },
  });
